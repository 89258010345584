import { dimensionLabels, dimensions } from 'exchange-common/data/dimensions'
import { noteTypes } from 'exchange-common/notes/noteTypes'
import { emptyStateReport } from 'exchange-common/report/report.js'
import { set } from 'vue'

import { ApiModel } from '~/plugins/api/model'

function createInitialStateForOrganisation() {
  return {
    fetchReportSummaryApi: new ApiModel(),
    fetchNoteApi: new ApiModel(),
    report: {},
    dimensionNotes: {},
    summaryNote: null
  }
}

const initialState = () => {
  return {
    organisations: {}
  }
}

function processScores(data, dimension) {
  const dimensionData = data[dimension]
  let identifier = ''

  if (dimension === dimensions.BIODIVERSITY) {
    identifier = 'name'
  } else {
    identifier = 'label'
  }

  let subDimensionData = dimensionData?.level1?.general || []

  if (dimension === dimensions.WATER) {
    subDimensionData = [...subDimensionData, ...(dimensionData?.level1?.detail || [])]
  }

  // Adding this here as it's a proxy for a score for carbon stored in soils
  if (dimension === dimensions.CLIMATE_CHANGE) {
    const somScore = (data[dimensions.SOILS]?.level1?.general || []).find(
      item => item.label === 'Soil organic matter'
    )

    subDimensionData = [...subDimensionData, somScore]
  }

  return {
    overall: dimensionData.overall,
    subdimensions: subDimensionData.reduce((acc, item) => {
      acc[item[identifier]] = item.score
      return acc
    }, {})
  }
}

export const state = () => initialState()

export const getters = {
  currentOrganisationId(state, getters, rootState, rootGetters) {
    return rootGetters['auth/getCurrentOrganisation']?.id
  },

  reportSummaries(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.report
  },

  summaryNote(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.summaryNote
  },

  dimensionScores: (state, getters) => dimension => {
    const data = getters.reportSummaries
    const dimensionData = data?.[dimension]

    if (dimensionData) {
      if (dimensionData?.extra?.ready) {
        return processScores(data, dimension)
      } else {
        return processScores(emptyStateReport, dimension)
      }
    }

    return null
  },

  dimensionOverviews(state, getters) {
    const pageUrl = '/platform/report'
    const dimensionUrls = {
      [dimensions.ANIMAL_WELFARE]: `${pageUrl}/animal-welfare`,
      [dimensions.CLIMATE_CHANGE]: `${pageUrl}/carbon`,
      [dimensions.BIODIVERSITY]: `${pageUrl}/biodiversity`,
      [dimensions.SOCIAL]: `${pageUrl}/social`,
      [dimensions.SOILS]: `${pageUrl}/soils`,
      [dimensions.WATER]: `${pageUrl}/water`
    }
    const scores = {}

    Object.values(dimensions).forEach(dimension => {
      scores[dimension] = {
        label: dimensionLabels[dimension],
        dimension,
        score: getters.reportSummaries?.[dimension]?.overall || 0,
        isReady: getters.reportSummaries?.[dimension]?.extra?.ready ?? false,
        isHidden: getters.reportSummaries?.[dimension]?.extra?.hidden ?? false,
        missingData:
          getters.reportSummaries?.[dimension]?.extra?.missingData?.filter(
            data => !data.item.includes('Farm is in')
          ) ?? [],
        maxScore: 5,
        to: dimensionUrls[dimension]
      }
    })

    return scores
  },

  reportOrEmptyStateData: (state, getters) => dimension => {
    const report = getters.reportSummaries?.[dimension]

    if (report) {
      if (report?.extra?.ready) {
        return report
      } else {
        return emptyStateReport[dimension]
      }
    }

    return null
  },

  reportIsReady: (state, getters) => dimension => {
    return getters.reportSummaries?.[dimension]?.extra?.ready
  },

  dimensionReadyStatus(state, getters) {
    return Object.values(dimensions).reduce((acc, dimension) => {
      acc[dimension] = getters.reportIsReady(dimension)
      return acc
    }, {})
  },

  getNoteByDimension: (state, getters) => dimension => {
    return state.organisations[getters.currentOrganisationId]?.notes?.[dimension]
  },

  fetchReportSummaryApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.fetchReportSummaryApi
  },

  fetchNoteApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.fetchNoteApi
  },

  isReportLoading(state, getters) {
    return getters.fetchReportSummaryApi?.isLoading
  }
}

export const actions = {
  async getReportSummary({ commit, getters, rootGetters }) {
    if (!rootGetters['device/isOnline'] || getters.isReportLoading) {
      return
    }

    commit('insertOrganisationIfRequired', getters.currentOrganisationId)

    const { response } = await this.$api
      .reporting(getters.fetchReportSummaryApi)
      .useStorePath(`report.organisations.${getters.currentOrganisationId}.fetchReportSummaryApi`)
      .getReportSummary()

    if (response.data && response.data.meta) {
      commit('setReport', { summary: response.data.data, organisationId: response.data.meta.organisationId })
    }
  },

  async getDimensionNotes({ commit, getters, rootGetters }) {
    if (!rootGetters['device/isOnline']) {
      return
    }

    commit('insertOrganisationIfRequired', getters.currentOrganisationId)

    const { response } = await this.$api
      .note(getters.fetchNoteApi)
      .useStorePath(`report.organisations.${getters.currentOrganisationId}.fetchNoteApi`)
      .getAllByType(noteTypes.REPORT_DIMENSION_SUMMARY)

    if (response.data && response.data.meta) {
      commit('setNotes', { notes: response.data.notes, organisationId: response.data.meta.organisationId })
    }
  }
}

export const mutations = {
  setReport(state, { summary, organisationId }) {
    this.commit('report/insertOrganisationIfRequired', organisationId)

    set(state.organisations[organisationId], 'report', summary)
  },

  setNotes(state, { notes, organisationId }) {
    this.commit('report/insertOrganisationIfRequired', organisationId)

    const keyedNotes = {}

    notes.forEach(note => {
      keyedNotes[note.dimension] = note
    })

    set(state.organisations[organisationId], 'notes', keyedNotes)
  },

  setSummaryNote(state, { summaryNote, organisationId }) {
    this.commit('report/insertOrganisationIfRequired', organisationId)

    set(state.organisations[organisationId], 'summaryNote', summaryNote)
  },

  reset(state) {
    Object.assign(state, initialState())
  },

  insertOrganisationIfRequired(state, organisationId) {
    if (organisationId && !state.organisations[organisationId]) {
      set(state.organisations, organisationId, createInitialStateForOrganisation())
    }
  }
}
