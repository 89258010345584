import { render, staticRenderFns } from "./AppLoader.vue?vue&type=template&id=5b65477a&"
import script from "./AppLoader.vue?vue&type=script&lang=js&"
export * from "./AppLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/image/Img.vue').default,BannerOffline: require('/home/runner/work/exchange/exchange/packages/client/src/components/banners/BannerOffline.vue').default,Loader: require('/home/runner/work/exchange/exchange/packages/client/src/components/utils/loader/Loader.vue').default,Button: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/button/Button.vue').default,Notification: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/notification/Notification.vue').default})
