import { ExchangeApi } from '~/services/api/exchange'

export class ZonationService extends ExchangeApi {
  async run(model) {
    return await this.post('/zonation/run', model)
  }

  async runAutoZonation() {
    return await this.post('/zonation/run/auto')
  }

  async getAutoZonationStatus() {
    return await this.get('/zonation/run/auto/status')
  }

  async save(model) {
    return await this.post('/zonation', model)
  }

  async saveSamplingFields(model) {
    return await this.post('/zonation/sampling-fields', model)
  }

  async getSbiGeoJson({ sbiNumber, existingParcelIds }) {
    return await this.post(`/zonation/sbi/${sbiNumber}`, {
      existingParcelIds
    })
  }

  async getShapeFileGeoJson({ publicUrl, existingParcelIds }) {
    return await this.post(`/zonation/shapefile`, {
      publicUrl,
      existingParcelIds
    })
  }

  async getNewParcelProperties(model) {
    return await this.post('/zonation/parcel/properties', model)
  }

  async saveParcels(model) {
    return await this.post('/zonation/parcel', model)
  }

  async generateMapPdf(samplingFieldsGeoJson) {
    return await this.post(`/zonation/pdf`, { samplingFieldsGeoJson })
  }

  async confirmSamplePlan(farmId) {
    return await this.post(`/zonation/farm/${farmId}/plan`)
  }
}
