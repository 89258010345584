export function truncateString(targetString, maxLength = 14) {
  if (targetString) {
    return targetString.length > maxLength ? `${targetString.substr(0, maxLength)}...` : targetString
  } else {
    throw new Error('No string provided to truncate')
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function removeSpecialCharacters(targetString) {
  return targetString.replace(/[^a-zA-Z ]/g, '')
}

export function composeInitialSurname(targetString) {
  const words = targetString.split(' ')

  return `${words[0].charAt(0)}. ${words[words.length - 1]}`
}

export function convertCamelCaseToSentence(variable) {
  let sentence = ''

  for (const char of variable) {
    if (char === char.toUpperCase()) {
      sentence += ' '
    }

    sentence += char
  }

  return sentence
}

export function generateSlug(string) {
  return string
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') //remove diacritics
    .toLowerCase()
    .replace(/\s+/g, '-') //spaces to dashes
    .replace(/&/g, '-and-') //ampersand to and
    .replace(/[^\w\-]+/g, '') //remove non-words
    .replace(/\-\-+/g, '-') //collapse multiple dashes
    .replace(/^-+/, '') //trim starting dash
    .replace(/-+$/, '') //trim ending dash
}

// Recursively remove null, undefined, empty objects and empty arrays
export function removeEmptyValues(dirtyObject) {
  let item

  if (Array.isArray(dirtyObject)) {
    item = dirtyObject.map(x => removeEmptyValues(x)).filter(value => value !== undefined)
    return item.length ? item : undefined
  } else if (dirtyObject && typeof dirtyObject === 'object') {
    item = {}
    Object.keys(dirtyObject).forEach(key => {
      const value = removeEmptyValues(dirtyObject[key])

      if (value !== undefined) {
        item[key] = value
      }
    })
    return Object.keys(item).length ? item : undefined
  } else {
    return dirtyObject === null ? undefined : dirtyObject
  }
}

export function toLocaleFixed(value, numberFractionDigits = 2) {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: numberFractionDigits,
    maximumFractionDigits: numberFractionDigits
  })
}
