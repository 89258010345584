import { point } from '@turf/helpers'
import { dimensions } from 'exchange-common/data/dimensions'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { set } from 'vue'

import {
  BirdSample,
  EarthwormsSample,
  HedgerowSample,
  PlantSample,
  VessSample,
  WaterSample
} from '~/lib/entities/samples'
import {
  getSamplesForLocation,
  getSamplesGroupedByUniqueLocation,
  maxDistanceWaterPlannedLocationKm
} from '~/lib/samplingHelpers'
import { ApiModel } from '~/plugins/api/model'

function createInitialStateForOrganisation() {
  return {
    birdsApi: new ApiModel(),
    plantsApi: new ApiModel(),
    hedgerowsApi: new ApiModel(),
    vessApi: new ApiModel(),
    earthwormsApi: new ApiModel(),
    waterApi: new ApiModel(),
    soilLabResultsApi: new ApiModel(),

    birds: [],
    plants: [],
    hedgerows: [],
    vess: [],
    earthworms: [],
    water: [],
    soilLabResults: [],
    unsavedChanges: {
      birds: false,
      plants: false,
      hedgerows: false,
      vess: false,
      earthworms: false,
      water: false,
      log: {
        created: [],
        deleted: []
      }
    }
  }
}

const initialState = () => {
  return {
    organisations: {}
  }
}

export const state = () => initialState()

export const getters = {
  currentOrganisationId(state, getters, rootState, rootGetters) {
    return rootGetters['auth/getCurrentOrganisation']?.id
  },

  birds(state, getters) {
    return (state.organisations?.[getters.currentOrganisationId]?.birds || [])
      .filter(bird => bird.action !== 'delete')
      .map(bird => new BirdSample(bird))
  },

  plants(state, getters) {
    return (state.organisations?.[getters.currentOrganisationId]?.plants || [])
      .filter(plant => plant.action !== 'delete')
      .map(plant => new PlantSample(plant))
  },

  hedgerows(state, getters) {
    return (state.organisations?.[getters.currentOrganisationId]?.hedgerows || [])
      .filter(hedgerow => hedgerow.action !== 'delete')
      .map(hedgerow => new HedgerowSample(hedgerow))
  },

  vess(state, getters) {
    return (state.organisations?.[getters.currentOrganisationId]?.vess || [])
      .filter(vess => vess.action !== 'delete')
      .map(vess => new VessSample(vess))
  },

  earthworms(state, getters) {
    return (state.organisations?.[getters.currentOrganisationId]?.earthworms || [])
      .filter(earthworm => earthworm.action !== 'delete')
      .map(earthworm => new EarthwormsSample(earthworm))
  },

  water(state, getters) {
    return (state.organisations?.[getters.currentOrganisationId]?.water || [])
      .filter(sample => sample.action !== 'delete')
      .map(sample => new WaterSample(sample))
  },

  soilLabResults(state, getters) {
    return state.organisations?.[getters.currentOrganisationId]?.soilLabResults || []
  },

  // Unlike all other samples, water isn't tied to a parcel or zone but rather a planned geolocation
  // set in the intial Zonation stage
  waterSamplesByPlannedLocation(state, getters, rootState, rootGetters) {
    const existingSamples = getters.water
    const plannedLocations = rootGetters['farm/sampleLocations']

    const samplesByPlannedLocation = {}

    plannedLocations.forEach(location => {
      // Find all water samples within 50m of the planned location
      const matchingSamples = getSamplesForLocation({
        coordinates: JSON.parse(location.location).coordinates,
        samples: existingSamples,
        maxDistanceKm: maxDistanceWaterPlannedLocationKm
      })

      samplesByPlannedLocation[location.id] = {
        samples: matchingSamples || [],
        locationFeature: JSON.parse(location.location),
        count: matchingSamples.length,
        minRequired: 1,
        percentageComplete: matchingSamples.length > 0 ? 100 : 0,
        isComplete: matchingSamples.length > 0
      }
    })

    return samplesByPlannedLocation
  },

  // Get a flat array of the completion percentages of each parcelZone
  flattenedParcelZonePercentages(state, getters) {
    return Object.values(getters.samplesGroupedByParcelZone).reduce((acc, parcel) => {
      const parcelPercentages = Object.values(parcel.zones).reduce((zoneAcc, zone) => {
        return [...zoneAcc, zone.percentageComplete]
      }, [])

      return [...acc, ...parcelPercentages]
    }, [])
  },

  inFieldSamplingPercentageComplete(state, getters) {
    // Water samples
    const waterSampleCompletePercentages = Object.values(getters.waterSamplesByPlannedLocation).map(
      location => location.percentageComplete
    )

    // Flatten samplesGroupedByParcelZone
    const flattenedParcelZonePercentages = getters.flattenedParcelZonePercentages

    const samplePercentages = [...waterSampleCompletePercentages, ...flattenedParcelZonePercentages]

    return samplePercentages.reduce((acc, percentage) => acc + percentage, 0) / samplePercentages.length
  },

  hasCompletedInFieldSampling(state, getters) {
    return getters.inFieldSamplingPercentageComplete >= 100
  },

  overallPercentageComplete(state, getters) {
    // Water samples
    const waterSampleCompletePercentages = Object.values(getters.waterSamplesByPlannedLocation).map(
      location => location.percentageComplete
    )

    // Soil lab results
    const soilLabResultPercentage = Math.min(
      (getters.soilLabResults.length / getters.samplingParcelZoneCount) * 100,
      100
    )

    // Flatten samplesGroupedByParcelZone
    const flattenedParcelZonePercentages = getters.flattenedParcelZonePercentages

    const samplePercentages = [
      ...waterSampleCompletePercentages,
      ...[soilLabResultPercentage],
      ...flattenedParcelZonePercentages
    ]

    return Math.round(
      samplePercentages.reduce((acc, percentage) => acc + percentage, 0) / samplePercentages.length
    )
  },

  hasCompletedLabResults(state, getters) {
    return getters.soilLabResults.length >= getters.samplingParcelZoneCount
  },

  samplingParcelZoneCount(state, getters) {
    return getters.samplingParcelZones.length
  },

  // Get unique zone records for parcels that have been chosen for sampling
  samplingParcelZones(state, getters, rootState, rootGetters) {
    if (!rootGetters['farm/farm']?.samplingParcels?.length) {
      return []
    }

    return rootGetters['farm/farm'].samplingParcels.reduce((acc, samplingParcel) => {
      return [
        ...acc,
        {
          zone: samplingParcel.zone,
          parcelId: samplingParcel.parcelId
        }
      ]
    }, [])
  },

  getSamplesForParcelZone: (state, getters) => (parcelId, zone, sampleType) => {
    if (sampleType) {
      return getters.samplesGroupedByParcelZone[parcelId]?.zones[zone]?.sampleType[sampleType]
    } else {
      return getters.samplesGroupedByParcelZone[parcelId]?.zones[zone]
    }
  },

  samplesGroupedByParcelZone(state, getters) {
    const samplingParcels = getters.samplingParcelZones

    const sampleTypes = [
      { type: 'vess', samples: getters.vess, minRequired: 3 },
      { type: 'earthworms', samples: getters.earthworms, minRequired: 3 },
      { type: 'plants', samples: getters.plants, minRequired: 3, countBasedOnLocation: true },
      { type: 'birds', samples: getters.birds, minRequired: 1, countBasedOnLocation: true },
      { type: 'hedgerows', samples: getters.hedgerows, minRequired: 1 }
    ]

    const groupedSamples = {}

    samplingParcels.forEach(parcel => {
      const { zone, parcelId } = parcel

      if (!groupedSamples[parcelId]) {
        groupedSamples[parcelId] = {
          zones: {}
        }
      }

      if (!groupedSamples[parcelId].zones[zone]) {
        groupedSamples[parcelId].zones[zone] = {
          percentageComplete: 0,
          isComplete: false,
          sampleType: {}
        }
      }

      sampleTypes.forEach(sampleGroup => {
        if (!groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type]) {
          groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type] = {
            samples: [],
            count: 0,
            minRequired: sampleGroup.minRequired,
            percentageComplete: 0,
            isComplete: false
          }
        }

        sampleGroup.samples
          .filter(
            sample => sample.location.parcel === parcelId && parseInt(sample.location.zone) === parseInt(zone)
          )
          .forEach(sample => {
            groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].samples.push(sample)
          })

        // Work out count
        let count = 0

        if (sampleGroup.countBasedOnLocation) {
          const groupedSamplesByLocation = getSamplesGroupedByUniqueLocation(
            groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].samples
          )

          count = Object.keys(groupedSamplesByLocation).length
        } else {
          count = groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].samples.length
        }

        groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].count = count

        // Work out percentage complete for this sample type
        groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].percentageComplete = Math.round(
          (groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].count /
            groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].minRequired) *
            100
        )

        // Stop more samples pushing it over 100%
        if (groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].percentageComplete > 100) {
          groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].percentageComplete = 100
        }

        // Mark as complete if >= 100%
        groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].isComplete =
          groupedSamples[parcelId].zones[zone].sampleType[sampleGroup.type].percentageComplete >= 100
      })

      // Work out percentage complete for this zone
      const sumPercentageComplete = Object.keys(groupedSamples[parcelId].zones[zone].sampleType).reduce(
        (acc, sampleType) => {
          return acc + groupedSamples[parcelId].zones[zone].sampleType[sampleType].percentageComplete
        },
        0
      )

      const totalAvailablePercentage = sampleTypes.length * 100

      groupedSamples[parcelId].zones[zone].percentageComplete = Math.round(
        (sumPercentageComplete / totalAvailablePercentage) * 100
      )

      if (groupedSamples[parcelId].zones[zone].percentageComplete >= 100) {
        groupedSamples[parcelId].zones[zone].isComplete = true
      }
    })

    return groupedSamples
  },

  birdsApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.birdsApi || {}
  },

  plantsApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.plantsApi || {}
  },

  hedgerowsApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.hedgerowsApi || {}
  },

  vessApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.vessApi || {}
  },

  earthwormsApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.earthwormsApi || {}
  },

  waterApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.waterApi || {}
  },

  soilLabResultsApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.soilLabResultsApi || {}
  },

  unsavedChanges(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.unsavedChanges
  },

  changelog(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.unsavedChanges?.log || {}
  }
}

export const actions = {
  async fetchSamples({ commit, getters, rootGetters, state }, sampleType) {
    const isOffline = !rootGetters['device/isOnline']
    const hasUnsavedChanges = getters.unsavedChanges?.[sampleType]
    const farm = rootGetters['farm/farm']

    if (isOffline || hasUnsavedChanges || !getters.currentOrganisationId || !farm.id) {
      return
    }

    commit('insertOrganisationIfRequired', getters.currentOrganisationId)

    const { response } = await this.$api
      .sampling(state.organisations[getters.currentOrganisationId]?.[`${sampleType}Api`])
      .useStorePath(`sampling.organisations.${getters.currentOrganisationId}.${sampleType}Api`)
      .fetchSamples(farm.id, sampleType)

    if (response.data) {
      commit('setSamples', { organisationId: getters.currentOrganisationId, sampleType, data: response.data })
    }
  },

  async addSample({ commit, dispatch, state, getters }, { sampleType, sampleData, locationData, sampleNotes }) {
    const id = nanoid()

    const apiMockedSample = {
      action: 'post',
      id,
      ...sampleData,
      location: {
        id: nanoid(),
        location: point(locationData.coordinates).geometry,
        accuracyMeters: locationData.accuracyMeters,
        zone: locationData.zone,
        parcel: locationData.parcel
      },
      sampleNotes
    }

    commit('setSamples', {
      organisationId: getters.currentOrganisationId,
      sampleType,
      data: [...(state.organisations[getters.currentOrganisationId]?.[sampleType] || []), apiMockedSample]
    })

    const log = cloneDeep(getters.changelog)

    log.created = [...(log.created || []), id]
    commit('setChangelog', { organisationId: getters.currentOrganisationId, newLog: log })
    commit('setUnsavedChanges', {
      dimension: [sampleType],
      value: true,
      organisationId: getters.currentOrganisationId
    })

    await dispatch('saveSamples', sampleType)
  },

  async deleteSample({ state, getters, commit, dispatch }, { id, sampleType }) {
    console.log('got here')
    const clonedSamples = cloneDeep(state.organisations[getters.currentOrganisationId]?.[sampleType] || [])
    const sample = clonedSamples.find(s => s.id === id)

    if (sample) {
      commit('setUnsavedChanges', {
        dimension: sampleType,
        value: true,
        organisationId: getters.currentOrganisationId
      })

      sample.action = 'delete'
      commit('setSamples', {
        organisationId: getters.currentOrganisationId,
        sampleType,
        data: clonedSamples
      })

      const log = cloneDeep(getters.changelog)

      log.deleted = [...(log.deleted || []), id]
      commit('setChangelog', { organisationId: getters.currentOrganisationId, newLog: log })

      await dispatch('saveSamples', sampleType)
    }
  },

  async saveSamples({ rootGetters, getters, commit, state }, sampleType) {
    const isOffline = !rootGetters['device/isOnline']
    const farm = rootGetters['farm/farm']

    if (isOffline || !getters.currentOrganisationId || !farm.id) {
      return
    }

    const samples = state.organisations[getters.currentOrganisationId]?.[sampleType] || []

    // remove any entries that have been both created and deleted in this batch
    // only send the entries that have had an action
    const toBeCreatedIds = getters.changelog?.created || []
    const toBeDeletedIds = getters.changelog?.deleted || []
    const toBeRemovedFromCollectionIds = toBeCreatedIds.filter(id => toBeDeletedIds.includes(id))
    const samplesCleaned = samples.filter(
      sample => sample.action && !toBeRemovedFromCollectionIds.includes(sample.id)
    )

    const { response } = await this.$api
      .sampling(state.organisations[getters.currentOrganisationId]?.[`${sampleType}Api`])
      .useStorePath(`sampling.organisations.${getters.currentOrganisationId}.${sampleType}Api`)
      .processBatch(farm.id, sampleType, samplesCleaned)

    if (response.data) {
      commit('setSamples', { organisationId: getters.currentOrganisationId, sampleType, data: response.data })
    }

    commit('setUnsavedChanges', {
      dimension: sampleType,
      value: false,
      organisationId: getters.currentOrganisationId
    })

    commit('setChangelog', { organisationId: getters.currentOrganisationId, newLog: { created: [], deleted: [] } })
  },

  async syncLocalToServer({ getters, dispatch, commit }) {
    const typesToSave = Object.entries(getters.unsavedChanges).reduce((acc, [type, state]) => {
      if (type !== 'log' && state === true) {
        acc.push(type)
      }

      return acc
    }, [])

    this.$log.debug('typesToSave', JSON.stringify(typesToSave))

    await Promise.all(typesToSave.map(dimension => dispatch('saveSamples', dimension)))
  },

  async fetchSoilLabResults({ commit, getters }) {
    commit('insertOrganisationIfRequired', getters.currentOrganisationId)

    try {
      const { response } = await this.$api
        .dataEntry(getters.soilLabResultsApi)
        .useStorePath(`sampling.organisations.${getters.currentOrganisationId}.soilLabResultsApi`)
        .fetchData(dimensions.SOILS, 'lab')

      if (response.data) {
        commit('setSoilLabResults', {
          organisationId: getters.currentOrganisationId,
          data: response.data.data?.data?.results
        })
      }
    } catch (error) {
      // STUB for 404
    }
  }
}

export const mutations = {
  setSamples(state, { organisationId, sampleType, data }) {
    set(state.organisations[organisationId], sampleType, data)
  },

  setSoilLabResults(state, { organisationId, data }) {
    set(state.organisations[organisationId], 'soilLabResults', data)
  },

  insertOrganisationIfRequired(state, organisationId) {
    if (organisationId && !state.organisations[organisationId]) {
      set(state.organisations, organisationId, createInitialStateForOrganisation())
    }
  },

  setUnsavedChanges(state, { dimension, value, organisationId }) {
    set(state.organisations[organisationId]?.unsavedChanges, dimension, value)
  },

  setChangelog(state, { organisationId, newLog }) {
    set(state.organisations[organisationId]?.unsavedChanges, 'log', newLog)
  }
}
