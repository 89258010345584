import { hedgerowStructureOptions } from 'exchange-common/data/hedgerows'

class Sample {
  constructor(apiData) {
    const {
      id,
      recordedAt,
      location: { location, zone, parcel },
      sampleNotes
    } = apiData

    Object.assign(this, { id, recordedAt })

    this.location = {
      coordinates: typeof location === 'string' ? JSON.parse(location).coordinates : location.coordinates,
      zone,
      parcel
    }

    this.location.hasValidCoordinates = !this.location.coordinates.every(item => item === 0)

    this.sampleNotes = sampleNotes
  }

  getDetails() {
    return [
      {
        type: 'Location',
        value: [...this.location.coordinates].reverse().join(', ')
      },
      {
        type: 'Recorded at',
        value: this.recordedAt || 'unspecified'
      },
      {
        type: 'Notes',
        value: this.sampleNotes.map(note => note.note).join('\n')
      }
    ]
  }
}

export class BirdSample extends Sample {
  constructor(apiData) {
    super(apiData)

    const { species } = apiData

    Object.assign(this, { species })

    this.noNewSpeciesDetected = this.species?.length === 0
  }

  getType() {
    return 'birds'
  }

  getDisplayableType() {
    return 'Bird sample'
  }

  getTitle() {
    return this.species
  }

  getDetails() {
    return [
      {
        type: 'Species',
        value: this.species
      },
      ...super.getDetails()
    ]
  }
}

export class PlantSample extends Sample {
  constructor(apiData) {
    super(apiData)

    const { species } = apiData

    Object.assign(this, { species })

    this.noNewSpeciesDetected = this.species?.length === 0
  }

  getType() {
    return 'plants'
  }

  getDisplayableType() {
    return 'Plant sample'
  }

  getTitle() {
    return this.species
  }

  getDetails() {
    return [
      {
        type: 'Species',
        value: this.species
      },
      ...super.getDetails()
    ]
  }
}

export class HedgerowSample extends Sample {
  constructor(apiData) {
    super(apiData)

    const {
      averageBaseCanopyMeters,
      averageHeightMeters,
      averageWidthMeters,
      hedgeStructure,
      lengthMeters,
      measuredLine,
      numberOfHedgerowTrees,
      speciesOfTrees,
      totalHedgeGapsMeters
    } = apiData

    Object.assign(this, {
      averageBaseCanopyMeters,
      averageHeightMeters,
      averageWidthMeters,
      hedgeStructure,
      lengthMeters,
      numberOfHedgerowTrees,
      speciesOfTrees,
      totalHedgeGapsMeters
    })

    // NB: this is a bit of a hack. both these fields are required fields,
    // so by their absence we can infer that this entry was of this type
    this.noHedgerowsInField = !this.averageWidthMeters && !this.averageHeightMeters

    if (measuredLine) {
      const parsed = typeof measuredLine === 'string' ? JSON.parse(measuredLine) : measuredLine

      parsed.geometry = typeof parsed.geometry === 'string' ? JSON.parse(parsed.geometry) : parsed.geometry
      this.measuredLine = parsed
    } else {
      this.measuredLine = null
    }
  }

  getType() {
    return 'hedgerows'
  }

  getDisplayableType() {
    return 'Hedgerow sample'
  }

  getTitle() {
    return `Hedge structure: ${this.#getHedgerowStructureType()}`
  }

  getDetails() {
    return [
      {
        type: 'Average base canopy',
        value: `${this.averageBaseCanopyMeters}m`
      },
      {
        type: 'Average height',
        value: `${this.averageHeightMeters}m`
      },
      {
        type: 'Average width',
        value: `${this.averageWidthMeters}m`
      },
      {
        type: 'Length',
        value: `${this.lengthMeters}m`
      },
      // {
      //   type: 'Total gaps in hedgerow',
      //   value: `${this.totalHedgeGapsMeters}m`
      // },
      {
        type: 'Hedge structure',
        value: this.#getHedgerowStructureType()
      },
      {
        type: 'Number of trees in hedgerow',
        value: this.numberOfHedgerowTrees || 'none'
      },
      // {
      //   type: 'Species of trees',
      //   value: this.speciesOfTrees || 'not available'
      // },
      ...super.getDetails()
    ]
  }

  #getHedgerowStructureType() {
    return hedgerowStructureOptions.find(option => option.value === this.hedgeStructure)?.label || 'None'
  }
}

export class WaterSample extends Sample {
  constructor(apiData) {
    super(apiData)

    const { nitrate, phosphate, turbidity } = apiData

    Object.assign(this, { nitrate, phosphate, turbidity })
  }

  getType() {
    return 'water'
  }

  getDisplayableType() {
    return 'Water sample'
  }

  getTitle() {
    return `Location: ${this.location.coordinates.toString()}`
  }

  getShortDetails() {
    return [
      {
        type: 'Nitrate',
        value: `${this.nitrate} NO3-N mg/L`
      },
      {
        type: 'Phosphate',
        value: `${this.phosphate} PO4-P mg/L`
      },
      {
        type: 'Turbidity',
        value: `${this.turbidity} NTU`
      }
    ]
  }

  getDetails() {
    return [...this.getShortDetails(), ...super.getDetails()]
  }
}

export class VessSample extends Sample {
  constructor(apiData) {
    super(apiData)

    const { scoreTop, scoreBottom, topLayerDepthCM, bottomLayerDepthCM, soilType, notes } = apiData

    Object.assign(this, {
      scoreTop,
      scoreBottom,
      topLayerDepthCM,
      bottomLayerDepthCM,
      soilType,
      notes
    })
  }

  getType() {
    return 'vess'
  }

  getDisplayableType() {
    return 'VESS sample'
  }

  getTitle() {
    return 'VESS'
  }

  getDetails() {
    return [
      {
        type: 'Zone',
        value: this.location.zone
      },
      {
        type: 'Field',
        value: this.location.parcel
      },
      {
        type: 'Score top',
        value: this.scoreTop
      },
      {
        type: 'Score bottom',
        value: this.scoreBottom
      },
      {
        type: 'Top layer depth',
        value: `${this.topLayerDepthCM}cm`
      },
      {
        type: 'Bottom layer depth',
        value: `${this.bottomLayerDepthCM}cm`
      },
      {
        type: 'Soil type',
        value: this.soilType
      },
      ...super.getDetails()
    ]
  }
}

export class EarthwormsSample extends Sample {
  constructor(apiData) {
    super(apiData)

    const { soilType, totalCount } = apiData

    Object.assign(this, { soilType, totalCount })
  }

  getType() {
    return 'earthworms'
  }

  getDisplayableType() {
    return 'Earthworms sample'
  }

  getTitle() {
    return 'Earthworms'
  }

  getDetails() {
    return [
      {
        type: 'Zone',
        value: this.location.zone
      },
      {
        type: 'Field',
        value: this.location.parcel
      },
      {
        type: 'Total count',
        value: this.totalCount
      },
      {
        type: 'Soil type',
        value: this.soilType
      },
      ...super.getDetails()
    ]
  }
}
