import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a865be48 = () => interopDefault(import('../src/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _7c943f80 = () => interopDefault(import('../src/pages/locale.vue' /* webpackChunkName: "pages/locale" */))
const _5932b15d = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5e455318 = () => interopDefault(import('../src/pages/mobile-menu.vue' /* webpackChunkName: "pages/mobile-menu" */))
const _04ba9ad6 = () => interopDefault(import('../src/pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _6ec95802 = () => interopDefault(import('../src/pages/pilot.vue' /* webpackChunkName: "pages/pilot" */))
const _0455695c = () => interopDefault(import('../src/pages/platform/index.vue' /* webpackChunkName: "pages/platform/index" */))
const _734d5426 = () => interopDefault(import('../src/pages/register-complete.vue' /* webpackChunkName: "pages/register-complete" */))
const _2a6a92ec = () => interopDefault(import('../src/pages/setup/index.vue' /* webpackChunkName: "pages/setup/index" */))
const _38edca19 = () => interopDefault(import('../src/pages/admin/access-organisations/index.vue' /* webpackChunkName: "pages/admin/access-organisations/index" */))
const _5179f674 = () => interopDefault(import('../src/pages/admin/content/index.vue' /* webpackChunkName: "pages/admin/content/index" */))
const _09871eca = () => interopDefault(import('../src/pages/admin/farms/index.vue' /* webpackChunkName: "pages/admin/farms/index" */))
const _473bd493 = () => interopDefault(import('../src/pages/admin/farms/index/api-errors.vue' /* webpackChunkName: "pages/admin/farms/index/api-errors" */))
const _f40caf1a = () => interopDefault(import('../src/pages/admin/farms/index/list.vue' /* webpackChunkName: "pages/admin/farms/index/list" */))
const _0a408a17 = () => interopDefault(import('../src/pages/admin/farms/index/map.vue' /* webpackChunkName: "pages/admin/farms/index/map" */))
const _0b87fee9 = () => interopDefault(import('../src/pages/admin/map/index.vue' /* webpackChunkName: "pages/admin/map/index" */))
const _65f9d5a6 = () => interopDefault(import('../src/pages/admin/organisations/index.vue' /* webpackChunkName: "pages/admin/organisations/index" */))
const _a6b19568 = () => interopDefault(import('../src/pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _dd9faa34 = () => interopDefault(import('../src/pages/admin/settings/index/profile.vue' /* webpackChunkName: "pages/admin/settings/index/profile" */))
const _7eaf0353 = () => interopDefault(import('../src/pages/admin/settings/index/security.vue' /* webpackChunkName: "pages/admin/settings/index/security" */))
const _6800d206 = () => interopDefault(import('../src/pages/admin/team/index.vue' /* webpackChunkName: "pages/admin/team/index" */))
const _38cb6c15 = () => interopDefault(import('../src/pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _c0bd9eb6 = () => interopDefault(import('../src/pages/admin/zonation/index.vue' /* webpackChunkName: "pages/admin/zonation/index" */))
const _461fcb78 = () => interopDefault(import('../src/pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _14293a68 = () => interopDefault(import('../src/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _83e1c9ca = () => interopDefault(import('../src/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _f6d64b0c = () => interopDefault(import('../src/pages/auth/native-callback.vue' /* webpackChunkName: "pages/auth/native-callback" */))
const _0eb956d8 = () => interopDefault(import('../src/pages/auth/organisations.vue' /* webpackChunkName: "pages/auth/organisations" */))
const _ef96f612 = () => interopDefault(import('../src/pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _625a0926 = () => interopDefault(import('../src/pages/platform/action-plan/index.vue' /* webpackChunkName: "pages/platform/action-plan/index" */))
const _e0d4ad84 = () => interopDefault(import('../src/pages/platform/action-plan/index/action-items/index.vue' /* webpackChunkName: "pages/platform/action-plan/index/action-items/index" */))
const _7e9eb50e = () => interopDefault(import('../src/pages/platform/action-plan/index/recommendation/index.vue' /* webpackChunkName: "pages/platform/action-plan/index/recommendation/index" */))
const _c93feada = () => interopDefault(import('../src/pages/platform/data/index.vue' /* webpackChunkName: "pages/platform/data/index" */))
const _5d93f26c = () => interopDefault(import('../src/pages/platform/funding/index.vue' /* webpackChunkName: "pages/platform/funding/index" */))
const _0ac4b7af = () => interopDefault(import('../src/pages/platform/learning-hub/index.vue' /* webpackChunkName: "pages/platform/learning-hub/index" */))
const _448ff12e = () => interopDefault(import('../src/pages/platform/map/index.vue' /* webpackChunkName: "pages/platform/map/index" */))
const _d0069fc6 = () => interopDefault(import('../src/pages/platform/report/index.vue' /* webpackChunkName: "pages/platform/report/index" */))
const _39a87ccc = () => interopDefault(import('../src/pages/platform/settings/index.vue' /* webpackChunkName: "pages/platform/settings/index" */))
const _2da03453 = () => interopDefault(import('../src/pages/platform/settings/index/business.vue' /* webpackChunkName: "pages/platform/settings/index/business" */))
const _ab9c1b34 = () => interopDefault(import('../src/pages/platform/settings/index/profile.vue' /* webpackChunkName: "pages/platform/settings/index/profile" */))
const _f433a85a = () => interopDefault(import('../src/pages/platform/settings/index/security.vue' /* webpackChunkName: "pages/platform/settings/index/security" */))
const _3f6aa9e9 = () => interopDefault(import('../src/pages/platform/sharing/index.vue' /* webpackChunkName: "pages/platform/sharing/index" */))
const _7a82e7ae = () => interopDefault(import('../src/pages/setup/farm.vue' /* webpackChunkName: "pages/setup/farm" */))
const _3196d63c = () => interopDefault(import('../src/pages/admin/content/articles/index.vue' /* webpackChunkName: "pages/admin/content/articles/index" */))
const _3aae9834 = () => interopDefault(import('../src/pages/admin/content/funding/index.vue' /* webpackChunkName: "pages/admin/content/funding/index" */))
const _d8617b14 = () => interopDefault(import('../src/pages/admin/content/quick-wins/index.vue' /* webpackChunkName: "pages/admin/content/quick-wins/index" */))
const _70d14cb1 = () => interopDefault(import('../src/pages/admin/organisations/clone.vue' /* webpackChunkName: "pages/admin/organisations/clone" */))
const _1edb08ab = () => interopDefault(import('../src/pages/auth/register/business.vue' /* webpackChunkName: "pages/auth/register/business" */))
const _bec1d0be = () => interopDefault(import('../src/pages/auth/register/farm.vue' /* webpackChunkName: "pages/auth/register/farm" */))
const _ece7a45e = () => interopDefault(import('../src/pages/partner/farms/new.vue' /* webpackChunkName: "pages/partner/farms/new" */))
const _d9d36308 = () => interopDefault(import('../src/pages/platform/data/carbon.vue' /* webpackChunkName: "pages/platform/data/carbon" */))
const _a6e72fde = () => interopDefault(import('../src/pages/platform/data/lab/index.vue' /* webpackChunkName: "pages/platform/data/lab/index" */))
const _06350f8e = () => interopDefault(import('../src/pages/platform/data/sampling/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index" */))
const _21576cbf = () => interopDefault(import('../src/pages/platform/data/sampling/index/overview.vue' /* webpackChunkName: "pages/platform/data/sampling/index/overview" */))
const _7ce9df85 = () => interopDefault(import('../src/pages/platform/data/sampling/index/soil-lab.vue' /* webpackChunkName: "pages/platform/data/sampling/index/soil-lab" */))
const _314dbc7e = () => interopDefault(import('../src/pages/platform/data/sampling/index/water.vue' /* webpackChunkName: "pages/platform/data/sampling/index/water" */))
const _6f7722d0 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/index" */))
const _3cce28d8 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/birds.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/birds" */))
const _2cc0d8a8 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/earthworms.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/earthworms" */))
const _b7dffb08 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/hedgerows.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/hedgerows" */))
const _b399834c = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/plants.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/plants" */))
const _086c097e = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/vess.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/vess" */))
const _cc900146 = () => interopDefault(import('../src/pages/platform/data/surveys/index.vue' /* webpackChunkName: "pages/platform/data/surveys/index" */))
const _82840750 = () => interopDefault(import('../src/pages/platform/data/water.vue' /* webpackChunkName: "pages/platform/data/water" */))
const _3730a0bc = () => interopDefault(import('../src/pages/platform/funding/natural-capital.vue' /* webpackChunkName: "pages/platform/funding/natural-capital" */))
const _083d6b38 = () => interopDefault(import('../src/pages/platform/funding/price-premiums.vue' /* webpackChunkName: "pages/platform/funding/price-premiums" */))
const _631eeffe = () => interopDefault(import('../src/pages/platform/funding/subsidies.vue' /* webpackChunkName: "pages/platform/funding/subsidies" */))
const _3d4df22a = () => interopDefault(import('../src/pages/platform/learning-hub/advice.vue' /* webpackChunkName: "pages/platform/learning-hub/advice" */))
const _6283ff3d = () => interopDefault(import('../src/pages/platform/learning-hub/case-studies.vue' /* webpackChunkName: "pages/platform/learning-hub/case-studies" */))
const _f47c289c = () => interopDefault(import('../src/pages/platform/learning-hub/tutorials.vue' /* webpackChunkName: "pages/platform/learning-hub/tutorials" */))
const _cadaadfa = () => interopDefault(import('../src/pages/platform/report/animal-welfare/index.vue' /* webpackChunkName: "pages/platform/report/animal-welfare/index" */))
const _629d5059 = () => interopDefault(import('../src/pages/platform/report/biodiversity/index.vue' /* webpackChunkName: "pages/platform/report/biodiversity/index" */))
const _4126f335 = () => interopDefault(import('../src/pages/platform/report/carbon/index.vue' /* webpackChunkName: "pages/platform/report/carbon/index" */))
const _0f4ae596 = () => interopDefault(import('../src/pages/platform/report/social/index.vue' /* webpackChunkName: "pages/platform/report/social/index" */))
const _5ffd0562 = () => interopDefault(import('../src/pages/platform/report/soils/index.vue' /* webpackChunkName: "pages/platform/report/soils/index" */))
const _3baa9236 = () => interopDefault(import('../src/pages/platform/report/water/index.vue' /* webpackChunkName: "pages/platform/report/water/index" */))
const _b059e6ec = () => interopDefault(import('../src/pages/admin/content/articles/new.vue' /* webpackChunkName: "pages/admin/content/articles/new" */))
const _553a26fc = () => interopDefault(import('../src/pages/admin/content/funding/new.vue' /* webpackChunkName: "pages/admin/content/funding/new" */))
const _1fcd3f44 = () => interopDefault(import('../src/pages/admin/content/quick-wins/new.vue' /* webpackChunkName: "pages/admin/content/quick-wins/new" */))
const _dcc84980 = () => interopDefault(import('../src/pages/platform/data/surveys/animal-welfare.vue' /* webpackChunkName: "pages/platform/data/surveys/animal-welfare" */))
const _3d5929d4 = () => interopDefault(import('../src/pages/platform/data/surveys/biodiversity.vue' /* webpackChunkName: "pages/platform/data/surveys/biodiversity" */))
const _b873f774 = () => interopDefault(import('../src/pages/platform/data/surveys/farm-practices.vue' /* webpackChunkName: "pages/platform/data/surveys/farm-practices" */))
const _85d41dbc = () => interopDefault(import('../src/pages/platform/data/surveys/water.vue' /* webpackChunkName: "pages/platform/data/surveys/water" */))
const _4777b9fc = () => interopDefault(import('../src/pages/platform/report/animal-welfare/antibiotics.vue' /* webpackChunkName: "pages/platform/report/animal-welfare/antibiotics" */))
const _6e034440 = () => interopDefault(import('../src/pages/platform/report/animal-welfare/outcomes.vue' /* webpackChunkName: "pages/platform/report/animal-welfare/outcomes" */))
const _2ff45661 = () => interopDefault(import('../src/pages/platform/report/biodiversity/birds.vue' /* webpackChunkName: "pages/platform/report/biodiversity/birds" */))
const _15e24385 = () => interopDefault(import('../src/pages/platform/report/biodiversity/hedgerows.vue' /* webpackChunkName: "pages/platform/report/biodiversity/hedgerows" */))
const _ced7509a = () => interopDefault(import('../src/pages/platform/report/biodiversity/landcover.vue' /* webpackChunkName: "pages/platform/report/biodiversity/landcover" */))
const _17d2c1f1 = () => interopDefault(import('../src/pages/platform/report/biodiversity/plants.vue' /* webpackChunkName: "pages/platform/report/biodiversity/plants" */))
const _647c28c2 = () => interopDefault(import('../src/pages/platform/report/biodiversity/practices.vue' /* webpackChunkName: "pages/platform/report/biodiversity/practices" */))
const _322417d3 = () => interopDefault(import('../src/pages/platform/report/biodiversity/woodland-connectivity.vue' /* webpackChunkName: "pages/platform/report/biodiversity/woodland-connectivity" */))
const _643d1d7f = () => interopDefault(import('../src/pages/platform/report/carbon/balance.vue' /* webpackChunkName: "pages/platform/report/carbon/balance" */))
const _9b6848a2 = () => interopDefault(import('../src/pages/platform/report/carbon/emissions.vue' /* webpackChunkName: "pages/platform/report/carbon/emissions" */))
const _4088ca8e = () => interopDefault(import('../src/pages/platform/report/carbon/stored-in-soils.vue' /* webpackChunkName: "pages/platform/report/carbon/stored-in-soils" */))
const _408fce79 = () => interopDefault(import('../src/pages/platform/report/carbon/stored-in-vegetation.vue' /* webpackChunkName: "pages/platform/report/carbon/stored-in-vegetation" */))
const _1579f4ae = () => interopDefault(import('../src/pages/platform/report/social/land-access.vue' /* webpackChunkName: "pages/platform/report/social/land-access" */))
const _415d414d = () => interopDefault(import('../src/pages/platform/report/soils/bulk-density.vue' /* webpackChunkName: "pages/platform/report/soils/bulk-density" */))
const _67bbc156 = () => interopDefault(import('../src/pages/platform/report/soils/earthworms.vue' /* webpackChunkName: "pages/platform/report/soils/earthworms" */))
const _83aa76d0 = () => interopDefault(import('../src/pages/platform/report/soils/ph.vue' /* webpackChunkName: "pages/platform/report/soils/ph" */))
const _95acfebe = () => interopDefault(import('../src/pages/platform/report/soils/som.vue' /* webpackChunkName: "pages/platform/report/soils/som" */))
const _24947e6f = () => interopDefault(import('../src/pages/platform/report/soils/vess.vue' /* webpackChunkName: "pages/platform/report/soils/vess" */))
const _5b72cf74 = () => interopDefault(import('../src/pages/platform/report/water/nitrogen-balance.vue' /* webpackChunkName: "pages/platform/report/water/nitrogen-balance" */))
const _12c6aa98 = () => interopDefault(import('../src/pages/platform/report/water/surface-runoff-avoidance.vue' /* webpackChunkName: "pages/platform/report/water/surface-runoff-avoidance" */))
const _40710c34 = () => interopDefault(import('../src/pages/platform/report/water/usage.vue' /* webpackChunkName: "pages/platform/report/water/usage" */))
const _cf7ac834 = () => interopDefault(import('../src/pages/platform/report/water/water-samples.vue' /* webpackChunkName: "pages/platform/report/water/water-samples" */))
const _9f7385c8 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _06599cd6 = () => interopDefault(import('../src/pages/admin/content/articles/view/_id.vue' /* webpackChunkName: "pages/admin/content/articles/view/_id" */))
const _d7c590b8 = () => interopDefault(import('../src/pages/admin/content/articles/_id.vue' /* webpackChunkName: "pages/admin/content/articles/_id" */))
const _7ca5d0c8 = () => interopDefault(import('../src/pages/admin/content/funding/_id.vue' /* webpackChunkName: "pages/admin/content/funding/_id" */))
const _0c176a5e = () => interopDefault(import('../src/pages/admin/content/quick-wins/_id.vue' /* webpackChunkName: "pages/admin/content/quick-wins/_id" */))
const _852d027e = () => interopDefault(import('../src/pages/admin/access-organisations/_id.vue' /* webpackChunkName: "pages/admin/access-organisations/_id" */))
const _65a5ece4 = () => interopDefault(import('../src/pages/admin/organisations/_id.vue' /* webpackChunkName: "pages/admin/organisations/_id" */))
const _214fd5a0 = () => interopDefault(import('../src/pages/admin/organisations/_id/activity.vue' /* webpackChunkName: "pages/admin/organisations/_id/activity" */))
const _66658b0a = () => interopDefault(import('../src/pages/admin/organisations/_id/overview.vue' /* webpackChunkName: "pages/admin/organisations/_id/overview" */))
const _406d87c7 = () => interopDefault(import('../src/pages/admin/organisations/_id/users.vue' /* webpackChunkName: "pages/admin/organisations/_id/users" */))
const _6a012086 = () => interopDefault(import('../src/pages/admin/users/_id.vue' /* webpackChunkName: "pages/admin/users/_id" */))
const _a768175e = () => interopDefault(import('../src/pages/admin/users/_id/activity.vue' /* webpackChunkName: "pages/admin/users/_id/activity" */))
const _49216f96 = () => interopDefault(import('../src/pages/admin/users/_id/notifications.vue' /* webpackChunkName: "pages/admin/users/_id/notifications" */))
const _1d3cac8a = () => interopDefault(import('../src/pages/admin/users/_id/overview.vue' /* webpackChunkName: "pages/admin/users/_id/overview" */))
const _06d3bef2 = () => interopDefault(import('../src/pages/auth/invited/_verificationCode.vue' /* webpackChunkName: "pages/auth/invited/_verificationCode" */))
const _ee8550b6 = () => interopDefault(import('../src/pages/auth/reset-password/_verificationCode/index.vue' /* webpackChunkName: "pages/auth/reset-password/_verificationCode/index" */))
const _bf024edc = () => interopDefault(import('../src/pages/platform/learning-hub/_slug/_id.vue' /* webpackChunkName: "pages/platform/learning-hub/_slug/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _a865be48,
    name: "admin"
  }, {
    path: "/locale",
    component: _7c943f80,
    name: "locale"
  }, {
    path: "/maintenance",
    component: _5932b15d,
    name: "maintenance"
  }, {
    path: "/mobile-menu",
    component: _5e455318,
    name: "mobile-menu"
  }, {
    path: "/partner",
    component: _04ba9ad6,
    name: "partner"
  }, {
    path: "/pilot",
    component: _6ec95802,
    name: "pilot"
  }, {
    path: "/platform",
    component: _0455695c,
    name: "platform"
  }, {
    path: "/register-complete",
    component: _734d5426,
    name: "register-complete"
  }, {
    path: "/setup",
    component: _2a6a92ec,
    name: "setup"
  }, {
    path: "/admin/access-organisations",
    component: _38edca19,
    name: "admin-access-organisations"
  }, {
    path: "/admin/content",
    component: _5179f674,
    name: "admin-content"
  }, {
    path: "/admin/farms",
    component: _09871eca,
    name: "admin-farms",
    children: [{
      path: "api-errors",
      component: _473bd493,
      name: "admin-farms-index-api-errors"
    }, {
      path: "list",
      component: _f40caf1a,
      name: "admin-farms-index-list"
    }, {
      path: "map",
      component: _0a408a17,
      name: "admin-farms-index-map"
    }]
  }, {
    path: "/admin/map",
    component: _0b87fee9,
    name: "admin-map"
  }, {
    path: "/admin/organisations",
    component: _65f9d5a6,
    name: "admin-organisations"
  }, {
    path: "/admin/settings",
    component: _a6b19568,
    name: "admin-settings",
    children: [{
      path: "profile",
      component: _dd9faa34,
      name: "admin-settings-index-profile"
    }, {
      path: "security",
      component: _7eaf0353,
      name: "admin-settings-index-security"
    }]
  }, {
    path: "/admin/team",
    component: _6800d206,
    name: "admin-team"
  }, {
    path: "/admin/users",
    component: _38cb6c15,
    name: "admin-users"
  }, {
    path: "/admin/zonation",
    component: _c0bd9eb6,
    name: "admin-zonation"
  }, {
    path: "/auth/forgot-password",
    component: _461fcb78,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _14293a68,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _83e1c9ca,
    name: "auth-logout"
  }, {
    path: "/auth/native-callback",
    component: _f6d64b0c,
    name: "auth-native-callback"
  }, {
    path: "/auth/organisations",
    component: _0eb956d8,
    name: "auth-organisations"
  }, {
    path: "/auth/register",
    component: _ef96f612,
    name: "auth-register"
  }, {
    path: "/platform/action-plan",
    component: _625a0926,
    name: "platform-action-plan",
    children: [{
      path: "action-items",
      component: _e0d4ad84,
      name: "platform-action-plan-index-action-items"
    }, {
      path: "recommendation",
      component: _7e9eb50e,
      name: "platform-action-plan-index-recommendation"
    }]
  }, {
    path: "/platform/data",
    component: _c93feada,
    name: "platform-data"
  }, {
    path: "/platform/funding",
    component: _5d93f26c,
    name: "platform-funding"
  }, {
    path: "/platform/learning-hub",
    component: _0ac4b7af,
    name: "platform-learning-hub"
  }, {
    path: "/platform/map",
    component: _448ff12e,
    name: "platform-map"
  }, {
    path: "/platform/report",
    component: _d0069fc6,
    name: "platform-report"
  }, {
    path: "/platform/settings",
    component: _39a87ccc,
    name: "platform-settings",
    children: [{
      path: "business",
      component: _2da03453,
      name: "platform-settings-index-business"
    }, {
      path: "profile",
      component: _ab9c1b34,
      name: "platform-settings-index-profile"
    }, {
      path: "security",
      component: _f433a85a,
      name: "platform-settings-index-security"
    }]
  }, {
    path: "/platform/sharing",
    component: _3f6aa9e9,
    name: "platform-sharing"
  }, {
    path: "/setup/farm",
    component: _7a82e7ae,
    name: "setup-farm"
  }, {
    path: "/admin/content/articles",
    component: _3196d63c,
    name: "admin-content-articles"
  }, {
    path: "/admin/content/funding",
    component: _3aae9834,
    name: "admin-content-funding"
  }, {
    path: "/admin/content/quick-wins",
    component: _d8617b14,
    name: "admin-content-quick-wins"
  }, {
    path: "/admin/organisations/clone",
    component: _70d14cb1,
    name: "admin-organisations-clone"
  }, {
    path: "/auth/register/business",
    component: _1edb08ab,
    name: "auth-register-business"
  }, {
    path: "/auth/register/farm",
    component: _bec1d0be,
    name: "auth-register-farm"
  }, {
    path: "/partner/farms/new",
    component: _ece7a45e,
    name: "partner-farms-new"
  }, {
    path: "/platform/data/carbon",
    component: _d9d36308,
    name: "platform-data-carbon"
  }, {
    path: "/platform/data/lab",
    component: _a6e72fde,
    name: "platform-data-lab"
  }, {
    path: "/platform/data/sampling",
    component: _06350f8e,
    name: "platform-data-sampling",
    children: [{
      path: "overview",
      component: _21576cbf,
      name: "platform-data-sampling-index-overview"
    }, {
      path: "soil-lab",
      component: _7ce9df85,
      name: "platform-data-sampling-index-soil-lab"
    }, {
      path: "water",
      component: _314dbc7e,
      name: "platform-data-sampling-index-water"
    }, {
      path: ":parcelId",
      component: _6f7722d0,
      name: "platform-data-sampling-index-parcelId"
    }, {
      path: ":parcelId/birds",
      component: _3cce28d8,
      name: "platform-data-sampling-index-parcelId-birds"
    }, {
      path: ":parcelId/earthworms",
      component: _2cc0d8a8,
      name: "platform-data-sampling-index-parcelId-earthworms"
    }, {
      path: ":parcelId/hedgerows",
      component: _b7dffb08,
      name: "platform-data-sampling-index-parcelId-hedgerows"
    }, {
      path: ":parcelId/plants",
      component: _b399834c,
      name: "platform-data-sampling-index-parcelId-plants"
    }, {
      path: ":parcelId/vess",
      component: _086c097e,
      name: "platform-data-sampling-index-parcelId-vess"
    }]
  }, {
    path: "/platform/data/surveys",
    component: _cc900146,
    name: "platform-data-surveys"
  }, {
    path: "/platform/data/water",
    component: _82840750,
    name: "platform-data-water"
  }, {
    path: "/platform/funding/natural-capital",
    component: _3730a0bc,
    name: "platform-funding-natural-capital"
  }, {
    path: "/platform/funding/price-premiums",
    component: _083d6b38,
    name: "platform-funding-price-premiums"
  }, {
    path: "/platform/funding/subsidies",
    component: _631eeffe,
    name: "platform-funding-subsidies"
  }, {
    path: "/platform/learning-hub/advice",
    component: _3d4df22a,
    name: "platform-learning-hub-advice"
  }, {
    path: "/platform/learning-hub/case-studies",
    component: _6283ff3d,
    name: "platform-learning-hub-case-studies"
  }, {
    path: "/platform/learning-hub/tutorials",
    component: _f47c289c,
    name: "platform-learning-hub-tutorials"
  }, {
    path: "/platform/report/animal-welfare",
    component: _cadaadfa,
    name: "platform-report-animal-welfare"
  }, {
    path: "/platform/report/biodiversity",
    component: _629d5059,
    name: "platform-report-biodiversity"
  }, {
    path: "/platform/report/carbon",
    component: _4126f335,
    name: "platform-report-carbon"
  }, {
    path: "/platform/report/social",
    component: _0f4ae596,
    name: "platform-report-social"
  }, {
    path: "/platform/report/soils",
    component: _5ffd0562,
    name: "platform-report-soils"
  }, {
    path: "/platform/report/water",
    component: _3baa9236,
    name: "platform-report-water"
  }, {
    path: "/admin/content/articles/new",
    component: _b059e6ec,
    name: "admin-content-articles-new"
  }, {
    path: "/admin/content/funding/new",
    component: _553a26fc,
    name: "admin-content-funding-new"
  }, {
    path: "/admin/content/quick-wins/new",
    component: _1fcd3f44,
    name: "admin-content-quick-wins-new"
  }, {
    path: "/platform/data/surveys/animal-welfare",
    component: _dcc84980,
    name: "platform-data-surveys-animal-welfare"
  }, {
    path: "/platform/data/surveys/biodiversity",
    component: _3d5929d4,
    name: "platform-data-surveys-biodiversity"
  }, {
    path: "/platform/data/surveys/farm-practices",
    component: _b873f774,
    name: "platform-data-surveys-farm-practices"
  }, {
    path: "/platform/data/surveys/water",
    component: _85d41dbc,
    name: "platform-data-surveys-water"
  }, {
    path: "/platform/report/animal-welfare/antibiotics",
    component: _4777b9fc,
    name: "platform-report-animal-welfare-antibiotics"
  }, {
    path: "/platform/report/animal-welfare/outcomes",
    component: _6e034440,
    name: "platform-report-animal-welfare-outcomes"
  }, {
    path: "/platform/report/biodiversity/birds",
    component: _2ff45661,
    name: "platform-report-biodiversity-birds"
  }, {
    path: "/platform/report/biodiversity/hedgerows",
    component: _15e24385,
    name: "platform-report-biodiversity-hedgerows"
  }, {
    path: "/platform/report/biodiversity/landcover",
    component: _ced7509a,
    name: "platform-report-biodiversity-landcover"
  }, {
    path: "/platform/report/biodiversity/plants",
    component: _17d2c1f1,
    name: "platform-report-biodiversity-plants"
  }, {
    path: "/platform/report/biodiversity/practices",
    component: _647c28c2,
    name: "platform-report-biodiversity-practices"
  }, {
    path: "/platform/report/biodiversity/woodland-connectivity",
    component: _322417d3,
    name: "platform-report-biodiversity-woodland-connectivity"
  }, {
    path: "/platform/report/carbon/balance",
    component: _643d1d7f,
    name: "platform-report-carbon-balance"
  }, {
    path: "/platform/report/carbon/emissions",
    component: _9b6848a2,
    name: "platform-report-carbon-emissions"
  }, {
    path: "/platform/report/carbon/stored-in-soils",
    component: _4088ca8e,
    name: "platform-report-carbon-stored-in-soils"
  }, {
    path: "/platform/report/carbon/stored-in-vegetation",
    component: _408fce79,
    name: "platform-report-carbon-stored-in-vegetation"
  }, {
    path: "/platform/report/social/land-access",
    component: _1579f4ae,
    name: "platform-report-social-land-access"
  }, {
    path: "/platform/report/soils/bulk-density",
    component: _415d414d,
    name: "platform-report-soils-bulk-density"
  }, {
    path: "/platform/report/soils/earthworms",
    component: _67bbc156,
    name: "platform-report-soils-earthworms"
  }, {
    path: "/platform/report/soils/ph",
    component: _83aa76d0,
    name: "platform-report-soils-ph"
  }, {
    path: "/platform/report/soils/som",
    component: _95acfebe,
    name: "platform-report-soils-som"
  }, {
    path: "/platform/report/soils/vess",
    component: _24947e6f,
    name: "platform-report-soils-vess"
  }, {
    path: "/platform/report/water/nitrogen-balance",
    component: _5b72cf74,
    name: "platform-report-water-nitrogen-balance"
  }, {
    path: "/platform/report/water/surface-runoff-avoidance",
    component: _12c6aa98,
    name: "platform-report-water-surface-runoff-avoidance"
  }, {
    path: "/platform/report/water/usage",
    component: _40710c34,
    name: "platform-report-water-usage"
  }, {
    path: "/platform/report/water/water-samples",
    component: _cf7ac834,
    name: "platform-report-water-water-samples"
  }, {
    path: "/",
    component: _9f7385c8,
    name: "index"
  }, {
    path: "/admin/content/articles/view/:id?",
    component: _06599cd6,
    name: "admin-content-articles-view-id"
  }, {
    path: "/admin/content/articles/:id",
    component: _d7c590b8,
    name: "admin-content-articles-id"
  }, {
    path: "/admin/content/funding/:id",
    component: _7ca5d0c8,
    name: "admin-content-funding-id"
  }, {
    path: "/admin/content/quick-wins/:id?",
    component: _0c176a5e,
    name: "admin-content-quick-wins-id"
  }, {
    path: "/admin/access-organisations/:id?",
    component: _852d027e,
    name: "admin-access-organisations-id"
  }, {
    path: "/admin/organisations/:id",
    component: _65a5ece4,
    name: "admin-organisations-id",
    children: [{
      path: "activity",
      component: _214fd5a0,
      name: "admin-organisations-id-activity"
    }, {
      path: "overview",
      component: _66658b0a,
      name: "admin-organisations-id-overview"
    }, {
      path: "users",
      component: _406d87c7,
      name: "admin-organisations-id-users"
    }]
  }, {
    path: "/admin/users/:id",
    component: _6a012086,
    name: "admin-users-id",
    children: [{
      path: "activity",
      component: _a768175e,
      name: "admin-users-id-activity"
    }, {
      path: "notifications",
      component: _49216f96,
      name: "admin-users-id-notifications"
    }, {
      path: "overview",
      component: _1d3cac8a,
      name: "admin-users-id-overview"
    }]
  }, {
    path: "/auth/invited/:verificationCode?",
    component: _06d3bef2,
    name: "auth-invited-verificationCode"
  }, {
    path: "/auth/reset-password/:verificationCode",
    component: _ee8550b6,
    name: "auth-reset-password-verificationCode"
  }, {
    path: "/platform/learning-hub/:slug?/:id",
    component: _bf024edc,
    name: "platform-learning-hub-slug-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
