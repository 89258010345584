import { set } from 'lodash'

import { storePersist } from '~/lib/storePersist'
import { isNative } from '~/plugins/native/capacitor'

export const state = () => ({})

export const actions = {
  // We usually do this on logout to clear all our store data
  async resetAllModules({ commit, dispatch }) {
    this.$log.debug('Resetting all modules')

    commit('auth/reset', null, { root: true })
    commit('logs/reset', null, { root: true })
    commit('farm/reset', null, { root: true })
    commit('report/reset', null, { root: true })
    commit('organisations/reset', null, { root: true })
    commit('recommendation/reset', null, { root: true })
    commit('apiDataCache/reset', null, { root: true })

    if (isNative) {
      await storePersist.deleteAllLocalStateFiles()
    }
  }
}

export const mutations = {
  // A single mutation for updating any namespaced state with a single mutation to avoid lots of Vuex boilerplate
  setGlobalState(state, { statePath, newState }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, statePath, newState)
  }
}
