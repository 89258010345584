export const farmTypes = {
  CATTLE_DAIRY: 'Dairy',
  CATTLE_BEEF: 'Beef Cattle',
  SHEEP: 'Sheep',
  CHICKEN_LAYING: 'Laying Hens',
  CHICKEN_POULTRY: 'Chicken / Table Poultry',
  PIGS: 'Pigs',
  ARABLE: 'Arable crops (inc potatoes)',
  VEGETABLES: 'Vegetables'
}

export const animalFarmTypes = [
  farmTypes.CATTLE_DAIRY,
  farmTypes.CATTLE_BEEF,
  farmTypes.SHEEP,
  farmTypes.CHICKEN_LAYING,
  farmTypes.CHICKEN_POULTRY,
  farmTypes.PIGS
]
