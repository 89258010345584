import { ExchangeApi } from '~/services/api/exchange'

export class OrganisationService extends ExchangeApi {
  async register() {
    return await this.post(`/organisation/register`)
  }

  async update() {
    return await this.put(`/organisation`)
  }

  async updateAdmin(organisationId) {
    return await this.put(`/organisation/${organisationId}`)
  }

  async getAll() {
    return await this.get(`/organisation`)
  }

  async getAccessibleOrganisations(organisationId) {
    return await this.get(`/organisation/${organisationId}/accessible`)
  }

  async getById(organisationId, fetchFarm) {
    const query = fetchFarm ? '?fetchFarm=true' : ''

    return await this.get(`/organisation/${organisationId}${query}`)
  }

  async removeUser(userId) {
    return await this.delete(`/organisation/user/${userId}`)
  }

  async inviteUser() {
    return await this.post(`/organisation/invite`)
  }

  async updateUserRole(userId, role) {
    return await this.put(`/organisation/user/${userId}/role`, { role })
  }

  async saveRegisterSurvey(formData) {
    return await this.put(`/organisation/register/survey`, formData)
  }

  async validateInviteCode(code) {
    return await this.get(`/organisation/invite/${code}`)
  }
}
