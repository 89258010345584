import jwtDecode from 'jwt-decode'
import { cloneDeep, get, set } from 'lodash'
import Vue from 'vue'

import AuthEntity from '@/lib/entities/auth'
import ForgottenPasswordEntity from '@/lib/entities/forgottenPassword'
import ResetPasswordEntity from '@/lib/entities/resetPassword'
import { ApiModel } from '~/plugins/api/model'
import { isNative } from '~/plugins/native/capacitor'

export const STORAGE_KEY_ACCESS_TOKEN = 'access-token'
export const STORAGE_KEY_ACCESS_TOKEN_EXPIRES = 'access-token-expires'
export const STORAGE_KEY_REFRESH_TOKEN = 'refresh-token'
export const STORAGE_KEY_ORGANISATION = 'organisation'

const initialState = () => {
  return {
    user: {},
    permissions: [],
    activeOrganisationId: null,
    activeOrganisation: null,
    accessToken: null,
    accessTokenExpiresAt: null,
    refreshToken: null,
    isImpersonatingUser: false,
    impersonatingOriginalAdmin: {},
    socialProviderRedirectingTo: null,
    hasBiometrics: false,
    loginApi: new ApiModel(new AuthEntity().model),
    forgottenPasswordApi: new ApiModel(new ForgottenPasswordEntity().model),
    resetPasswordApi: new ApiModel(new ResetPasswordEntity().model),
    refreshTokenApi: new ApiModel()
  }
}

export const state = () => cloneDeep(initialState())

export const getters = {
  hasToken: state => {
    return state.accessToken !== null
  },

  getUser: state => {
    return state.user || {}
  },

  userOrganisations: state => {
    return get(state.user, 'organisations', [])
  },

  getCurrentOrganisation: state => {
    return state.activeOrganisation
  },

  organisationSwitchingOptions: (state, getters) => {
    return getters.userOrganisations.map(organisation => ({
      label: `Switch to ${organisation.isStaff ? 'Admin portal' : organisation.name}`,
      value: organisation.id
    }))
  },

  hasTriggeredForgotPassword: state => {
    return [200, 404].includes(state.forgottenPasswordApi.response.code)
  }
}

export const actions = {
  async loadExistingTokens({ dispatch, commit, state }) {
    const refreshToken = state.refreshToken || this.$cookies.get(STORAGE_KEY_REFRESH_TOKEN)

    if (refreshToken) {
      commit('setRefreshToken', refreshToken)
    }

    const accessToken = state.accessToken || this.$cookies.get(STORAGE_KEY_ACCESS_TOKEN)

    if (accessToken) {
      await dispatch('handleAccessToken', accessToken)
    }
  },

  loadExistingOrganisationId({ dispatch }) {
    if (this.$cookies.get(STORAGE_KEY_ORGANISATION)) {
      dispatch('changeOrganisationId', this.$cookies.get(STORAGE_KEY_ORGANISATION))
    }
  },

  async changeOrganisationId({ commit, dispatch, rootGetters }, organisationId) {
    this.$log.debug('Switching organisation', organisationId)

    if (rootGetters['device/isOnline']) {
      commit('setActiveOrganisationId', organisationId)
      await dispatch('app/fetchStatus', null, { root: true })
    } else {
      await dispatch('changeOrganisationIdOffline', organisationId)
    }

    dispatch('farm/resetZonesAndParcels', null, { root: true })
  },

  changeOrganisationIdOffline({ commit, dispatch, rootGetters }, organisationId) {
    this.$log.debug('Switching organisation offline', organisationId)

    // Find organisationId in list of offline synced organisations
    const matchingOfflineOrganisation = rootGetters['organisations/populatedOrganisations'].find(
      organisation => organisation.id === organisationId
    )

    if (!matchingOfflineOrganisation) {
      this.$log.error('User trying to switch to organisation not synced offline', organisationId)
      this.$notify.error('You cannot switch to an organisation that is not synced offline to your device yet.')

      return false
    }

    // Switch the user's active organisation
    commit('setActiveOrganisationId', organisationId)
    commit('setActiveOrganisation', matchingOfflineOrganisation.data)
  },

  async setupActiveOrganisation({ commit, state, dispatch }, organisation) {
    if (!organisation) {
      // We don't know the organisation, so lets find what to set it to
      const userOrganisations = get(state, 'user.organisations', [])

      if (userOrganisations.length === 0) {
        return false
      }

      if (userOrganisations.length === 1) {
        this.$analytics.setUserProperty('organisation', userOrganisations[0].name)
        return await dispatch('changeOrganisationId', userOrganisations[0].id)
      }

      const previousOrganisationId = this.$cookies.get(STORAGE_KEY_ORGANISATION)
      const existingOrganisation = userOrganisations.find(
        organisation => organisation.id === previousOrganisationId
      )

      if (previousOrganisationId && existingOrganisation) {
        this.$analytics.setUserProperty('organisation', existingOrganisation.name)
        return await dispatch('changeOrganisationId', previousOrganisationId)
      }
    } else {
      const previousOrganisation = state.activeOrganisation

      // We know the organisation so lets set it to that
      commit('setActiveOrganisation', organisation)

      // we only populate store when changing active/current organisation
      if (previousOrganisation?.id !== state.activeOrganisation?.id) {
        dispatch('app/populateStoresForOrganisation', organisation, { root: true })
      }
    }
  },

  clearActiveOrganisation({ commit }) {
    commit('setActiveOrganisationId', null)
    commit('setActiveOrganisation', null)
  },

  async login({ commit, state, dispatch, rootGetters }, formModel) {
    await this.$api.auth(state.loginApi).useStorePath('auth.loginApi').login(formModel)

    if (state.loginApi.response?.data?.accessToken) {
      dispatch('handleTokens', {
        accessToken: state.loginApi.response.data.accessToken,
        refreshToken: state.loginApi.response.data.refreshToken
      })
    }

    await dispatch('app/fetchStatus', null, { root: true })

    this.$analytics.addEvent('Auth: Logged in')

    await dispatch('handleUserResponse')
  },

  async handleUserResponse(
    { rootGetters, dispatch },
    { accessToken = null, refreshToken = null, shouldFetchStatus = false } = {}
  ) {
    if (accessToken && refreshToken) {
      dispatch('handleTokens', {
        accessToken,
        refreshToken
      })
    }

    if (shouldFetchStatus) {
      await dispatch('app/fetchStatus', null, { root: true })
    }

    const app = rootGetters['app/getApp']

    if (process.env.APP_ERROR_REPORTING_ENABLED === 'true') {
      this.$sentry.configureScope(scope => {
        scope.setUser({
          userId: app.user?.id,
          organisation: app.currentOrganisation?.name,
          email: app.user?.email,
          mobileNumber: app.user?.mobileNumber,
          name: app.user?.name
        })
      })
    }

    if (process.env.APP_ANALYTICS_ENABLED === 'true') {
      this.$analytics.setUserId(app.user?.id)
      this.$analytics.setUserProperty('role', app.role)
      this.$analytics.setUserProperty('email', app.user?.email)
      this.$analytics.setUserProperty('isPaid', app.isPaid)
      this.$analytics.setUserProperty('isStaffRole', app.isStaffRole)
      this.$analytics.setUserProperty('isAnimalFarmType', app.isAnimalFarmType)
      this.$analytics.setUserProperty('isArableFarmType', app.isArableFarmType)
      this.$analytics.setUserProperty('hasLegacyZonation', app.hasLegacyZonation)
    }
  },

  loginSocial({ commit }, { provider, returnTo }) {
    commit('setSocialProviderRedirectingTo', provider)

    if (!returnTo) {
      returnTo = window.location.href
    }

    this.$analytics.addEvent(`Auth: Redirecting to social login ${provider}`, returnTo)

    if (isNative) {
      // Open in native browser window
      window.open(
        `${process.env.API_BASE_URL}/auth/login/${provider}?returnTo=${encodeURIComponent(
          process.env.NATIVE_CALLBACK_URL
        )}`
      )
    } else {
      window.location = `${process.env.API_BASE_URL}/auth/login/${provider}?returnTo=${encodeURIComponent(
        returnTo
      )}`
    }
  },

  async refreshToken({ commit, state, dispatch, rootGetters }) {
    if (!rootGetters['app/getApp'].isOnline) {
      return false
    }

    try {
      const refreshToken = state.refreshToken || this.$cookies.get(STORAGE_KEY_REFRESH_TOKEN)

      if (!refreshToken) {
        throw new Error('No refresh token found')
      }

      this.$log.debug('Refreshing token')

      await this.$api.auth(state.refreshTokenApi).useStorePath('auth.refreshTokenApi').refreshToken(refreshToken)

      if (get(state.refreshTokenApi, 'response.data.accessToken')) {
        this.$log.debug('Token refreshed successfully')

        dispatch('handleTokens', {
          accessToken: state.refreshTokenApi.response.data.accessToken,
          refreshToken: state.refreshTokenApi.response.data.refreshToken
        })

        return state.refreshTokenApi.response.data.accessToken
      } else {
        throw new Error('Token refresh failed')
      }
    } catch (error) {
      this.$log.error('Auth: Refresh token failed', error)

      dispatch('handleAuthFailure')

      return false
    }
  },

  async handleAuthFailure({ state, commit, dispatch, rootGetters }, shouldFetchStatus = true) {
    if (!rootGetters['app/getApp'].isOnline) {
      return false
    }

    commit('reset')
    commit('app/reset', null, { root: true })
    dispatch('modal/closeAll', null, { root: true })

    if (shouldFetchStatus) {
      // Try to load sys again but without a token this time
      await dispatch('app/fetchStatus', null, { root: true })
    }
  },

  async logout({ state, dispatch }) {
    await this.$modal.closeAll()

    try {
      this.$analytics.addEvent('Auth: Logged out')

      await dispatch('resetAllModules', null, { root: true })

      if (this.$biometrics.isActive()) {
        await this.$biometrics.deleteCredentials()
      }

      this.$analytics.reset()

      await dispatch('app/fetchStatus', null, { root: true })
    } catch (error) {
      this.$log.error('Error signing out', error)
    }
  },

  async logoutAndRedirect({ state, dispatch }) {
    await dispatch('logout')
    this.$redirect.to('/auth/login')
  },

  async requestForgottenPassword({ state, commit, dispatch }, formModel) {
    await this.$api
      .auth(state.forgottenPasswordApi)
      .useStorePath('auth.forgottenPasswordApi')
      .forgotPassword(formModel)

    this.$analytics.addEvent('Auth: Forgotten password')
  },

  async resetPassword({ state, commit, dispatch }, formModel) {
    await this.$api.auth(state.resetPasswordApi).useStorePath('auth.resetPasswordApi').resetPassword(formModel)

    if (get(state.resetPasswordApi, 'response.data.accessToken')) {
      dispatch('handleTokens', {
        accessToken: state.resetPasswordApi.response.data.accessToken,
        refreshToken: state.resetPasswordApi.response.data.refreshToken
      })
    }

    await dispatch('app/fetchStatus', null, { root: true })

    this.$analytics.addEvent('Auth: Reset password')
  },

  async handleTokens({ state, commit, dispatch }, { accessToken, refreshToken }) {
    commit('setRefreshToken', refreshToken)
    await dispatch('handleAccessToken', accessToken)
  },

  async handleAccessToken({ commit, dispatch }, token) {
    const tokenData = jwtDecode(token)

    const expiresAt = this.$date.unix(tokenData.exp).toISOString()

    if (this.$date.unix(tokenData.exp).diff(this.$date.utc(), 'minutes') <= 5) {
      this.$log.debug('Auth: Previous token has expired')

      await dispatch('refreshToken')
    }

    commit('setAccessToken', { token, expiresAt })

    if (tokenData.impId) {
      commit('setState', { key: 'isImpersonatingUser', value: true })
      commit('setState', {
        key: 'impersonatingOriginalAdmin',
        value: {
          id: tokenData.impId,
          fullName: tokenData.impName
        }
      })
    }
  }
}

export const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  setAccessToken(state, { token, expiresAt }) {
    state.accessToken = token
    state.accessTokenExpiresAt = expiresAt

    this.$cookies.set(STORAGE_KEY_ACCESS_TOKEN, token, {
      expires: this.$date().add(365, 'day').toDate(),
      path: '/'
    })
  },

  setRefreshToken(state, token) {
    state.refreshToken = token

    this.$cookies.set(STORAGE_KEY_REFRESH_TOKEN, token, {
      expires: this.$date().add(365, 'day').toDate(),
      path: '/'
    })
  },

  setActiveOrganisationId(state, organisationId) {
    state.activeOrganisationId = organisationId

    this.$cookies.set(STORAGE_KEY_ORGANISATION, organisationId, {
      expires: this.$date().add(365, 'day').toDate(),
      path: '/'
    })
  },

  setActiveOrganisation(state, organisation) {
    // eslint-disable-next-line import/no-named-as-default-member
    Vue.set(state, 'activeOrganisation', organisation)
  },

  setSocialProviderRedirectingTo(state, provider) {
    state.socialProviderRedirectingTo = provider
  },

  setUser(state, user) {
    Vue.set(state, 'user', user)
  },

  setHasBiometrics(state, hasBiometrics) {
    state.hasBiometrics = hasBiometrics
  },

  setPermissions(state, permissions) {
    Vue.set(state, 'permissions', permissions)
  },

  reset(state) {
    this.$log.debug('Resetting auth module')

    this.$cookies.remove(STORAGE_KEY_ACCESS_TOKEN)
    this.$cookies.remove(STORAGE_KEY_ACCESS_TOKEN_EXPIRES)
    this.$cookies.remove(STORAGE_KEY_REFRESH_TOKEN)
    this.$cookies.remove(STORAGE_KEY_ORGANISATION)

    // Clear any interval callbacks not caught by component destroys
    if (this.$activityRefresh) {
      this.$activityRefresh.clear()
    }

    const hasBiometrics = state.hasBiometrics

    Object.assign(state, cloneDeep(initialState()))

    state.hasBiometrics = hasBiometrics
  }
}
