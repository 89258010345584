import { storePersist } from '~/lib/storePersist'
import { isNative, loadCapacitor, platform } from '~/plugins/native/capacitor'

import('@capacitor/core')

// We don't want capacitor code running server side so we only execute this client side, if runnning native app
export default async function ({ store, app, $log }, inject) {
  await loadCapacitor()

  let launchRedirectRoute = null

  if (isNative) {
    store.dispatch('device/changeStatusBarColorPrimary', { root: true })

    const { App } = await import('@capacitor/app')

    // Handle deep links (universal links) coming into the app and redirect appropriately
    App.addListener('appUrlOpen', async openListenerEvent => {
      $log.debug('Deep link launch:', openListenerEvent.url)

      try {
        const url = new URL(openListenerEvent.url)
        const params = url.searchParams

        if (url?.href?.startsWith(process.env.NATIVE_CALLBACK_URL)) {
          $log.debug('Auth: Handling auth callback')

          store.commit('auth/setSocialProviderRedirectingTo', null)

          const accessToken = params.get('accessToken')

          if (accessToken) {
            await store.dispatch('auth/handleAccessToken', accessToken)

            await store.dispatch('app/fetchStatus')

            $log.debug('Auth: Native user logged in via social')

            return app.router.push('/platform')
          }
        }

        const route = { path: url.pathname + url.search }

        if (store.state.app.isClientLoaded) {
          app.router.push(route)
        } else {
          launchRedirectRoute = route
        }
      } catch (error) {
        $log.error(`Failed to launch deep link - ${openListenerEvent.url}`, error)
      }
    })

    window.onNuxtReady(() => {
      $log.debug('Native Nuxt ready')

      if (launchRedirectRoute) {
        app.router.push(launchRedirectRoute)
      }

      store.dispatch('organisations/checkPopulatedOrganisations')
    })

    storePersist.setup(store)
  }

  // Generic small native functions
  const native = {
    openAppSettings: async () => {
      const { NativeSettings, AndroidSettings, IOSSettings } = await import('capacitor-native-settings')

      $log.debug('Opening native app settings', platform)

      NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      })
    },

    keepScreenAwake: async (keepAwake = true) => {
      const { KeepAwake } = await import('@capacitor-community/keep-awake')

      if (keepAwake) {
        await KeepAwake.keepAwake()
      } else {
        await KeepAwake.allowSleep()
      }
    },

    hideSplashScreen: async () => {
      if (isNative && !store.state.device.hasHiddenSplash) {
        const { SplashScreen } = await import('@capacitor/splash-screen')

        SplashScreen.hide()
        store.commit('device/setHasHiddenSplash')
      }
    },

    vibrate: async impactStyle => {
      const { Haptics, ImpactStyle } = await import('@capacitor/haptics')

      if (impactStyle === 'notification') {
        // Several quick pulses
        await Haptics.notification()
      } else if (impactStyle) {
        // Short pulse
        const impactConfig = {
          light: ImpactStyle.Light,
          medium: ImpactStyle.Medium,
          heavy: ImpactStyle.Heavy
        }

        await Haptics.impact({ style: impactConfig[impactStyle] })
      } else {
        // Longer continuous pulse
        await Haptics.vibrate()
      }
    }
  }

  // Inject into Nuxt context as $native
  inject('native', native)
}
