import { ExchangeApi } from '~/services/api/exchange'

export class ReportingService extends ExchangeApi {
  async getAdminStats() {
    return await this.get(`/reporting/admin/stats`)
  }

  async getReportSummary() {
    return await this.get(`/annual-report`)
  }

  async downloadReport(fileType = 'excel') {
    return await this.get(`/annual-report/download/${fileType}`)
  }

  async getFarmProgress(farmId) {
    return await this.get(`/reporting/user/farm/${farmId}/progress`)
  }
}
