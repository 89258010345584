// TODO: survey-updates: consider adding farm practices to dimensions rather than bolting them on in various places
// not sure what the side effects are of doing this though

import { capitalizeFirstLetter } from 'exchange-common/data/transform.js'
import { animalFarmTypes } from 'exchange-common/data/farmTypes.js'

export const dimensions = {
  SOILS: 'soils',
  CLIMATE_CHANGE: 'climate-change',
  BIODIVERSITY: 'biodiversity',
  ANIMAL_WELFARE: 'animal-welfare',
  WATER: 'water',
  SOCIAL: 'social'
}

export const dimensionLabels = {
  [dimensions.SOILS]: 'Soils',
  [dimensions.CLIMATE_CHANGE]: 'Carbon',
  [dimensions.BIODIVERSITY]: 'Biodiversity',
  [dimensions.ANIMAL_WELFARE]: 'Animal Welfare',
  [dimensions.WATER]: 'Water',
  [dimensions.SOCIAL]: 'Social'
}

export const dimensionsWithQuestions = [dimensions.WATER, dimensions.BIODIVERSITY, dimensions.ANIMAL_WELFARE]

export function getQuestionDimensions(organisation) {
  // TODO: might be worth thinking about adding isAnimalFarmType as a virtual property of the Organisation model
  const farmTypes = organisation.typeOfFarm?.split(',') || []
  const isAnimalFarmType = animalFarmTypes.filter(type => farmTypes.includes(type)).length > 0

  return [
    dimensions.WATER,
    dimensions.BIODIVERSITY,
    'farm-practices',
    ...(isAnimalFarmType ? [dimensions.ANIMAL_WELFARE] : [])
  ]
}

export const dimensionsWithLabDataEntry = [
  dimensions.SOILS,
  dimensions.BIODIVERSITY,
  dimensions.CLIMATE_CHANGE,
  dimensions.WATER
]
export const dimensionsWithFieldSamplingDataEntry = [dimensions.SOILS, dimensions.BIODIVERSITY, dimensions.WATER]

export const dimensionsList = Object.values(dimensions)

export const dimensionOptions = dimensionsList.map(dimension => ({
  value: dimension,
  label: capitalizeFirstLetter(dimension).replace('-', ' ')
}))

export const dimensionOptionsWithQuestions = dimensionsWithQuestions.map(dimension => ({
  value: dimension,
  label: capitalizeFirstLetter(dimension).replace('-', ' ')
}))

export const dimensionOptionsWithLabDataEntry = dimensionsWithLabDataEntry.map(dimension => ({
  value: dimension,
  label: capitalizeFirstLetter(dimension).replace('-', ' ')
}))

export const dimensionOptionsWithFieldSamplingDataEntry = dimensionsWithFieldSamplingDataEntry.map(dimension => ({
  value: dimension,
  label: capitalizeFirstLetter(dimension).replace('-', ' ')
}))

export const dimensionColors = {
  [dimensions.SOILS]: '#824527',
  [dimensions.WATER]: '#146C9C',
  [dimensions.BIODIVERSITY]: '#6E2372',
  [dimensions.CLIMATE_CHANGE]: '#4F953B',
  [dimensions.SOCIAL]: '#EAE25E',
  [dimensions.ANIMAL_WELFARE]: '#77FB84'
}

export const dimensionColorsText = {
  [dimensions.SOILS]: '#FFF',
  [dimensions.WATER]: '#FFF',
  [dimensions.BIODIVERSITY]: '#FFF',
  [dimensions.CLIMATE_CHANGE]: '#FFF',
  [dimensions.SOCIAL]: '#454659',
  [dimensions.ANIMAL_WELFARE]: '#454659'
}
