import { ExchangeApi } from '~/services/api/exchange'

export class AuthService extends ExchangeApi {
  async login(postModel) {
    return await this.post(`/auth/login`, postModel)
  }

  async logout() {
    return await this.post(`/auth/logout`)
  }

  async registerUser() {
    return await this.post(`/auth/register/user`)
  }

  async forgotPassword(postModel) {
    return await this.post(`/auth/forgot-password`, postModel)
  }

  async resetPassword(postModel) {
    return await this.post(`/auth/reset-password`, postModel)
  }

  async changePassword(postModel) {
    return await this.post(`/auth/change-password`)
  }

  async refreshToken(refreshToken) {
    return await this.post(`/auth/refresh`, { refreshToken })
  }

  async acceptInvite() {
    return await this.put(`/auth/invite-accept`)
  }
}
