import { render, staticRenderFns } from "./NotificationFloating.vue?vue&type=template&id=3fc09683&scoped=true&"
import script from "./NotificationFloating.vue?vue&type=script&lang=js&"
export * from "./NotificationFloating.vue?vue&type=script&lang=js&"
import style0 from "./NotificationFloating.vue?vue&type=style&index=0&id=3fc09683&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc09683",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/button/Button.vue').default})
